import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/WeeksSmartInstallments"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { BottomCta, RefinRedesign } from "../../components/BottomCta"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { Partners } from "../../components/PartnersNew"
import { Reviews } from "../../components/Reviews"
import { Questions } from "../../components/Questions"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { SmartWeeks } from "../../components/SmartWeeks"
import { TwoChoice } from "../../components/twoChoice"
import { QUESTIONS_FOR_HALVA_PKW } from "../../components/Questions/helpers"

const title = "Недели умных рассрочек по карте «Халва» — оформите карту онлайн и активируйте акцию"

const description =
  "Покупайте одежду, обувь, ювелирные изделия и путешествия в рассрочку на 36 месяцев по карте «Халва»! Узнайте сроки и подробные условия участия в акции, оформите карту Халва и активируйте «Недели умных рассрочек»"

const formTitle = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const textBlockTitle = (
  <>
    Есть Халва – активируйте акцию,{" "}
    <span> совершайте покупки, участвуйте в розыгрыше кэшбэка до 100%</span>
  </>
)

const secondTextBlockTitle = (
  <>
    Нет Халвы – оформляйте карту{" "}
    <span>и после получения не забудьте зарегистрироваться в акции</span>
  </>
)

const parntersSubTitle = (
  <>
    C 17 марта по 27 апреля покупайте в&nbsp;рассрочку на 12 месяцев и&nbsp;участвуйте
    в&nbsp;розыгрыше кэшбэка до 100%. Увеличенная рассрочка доступна на&nbsp;все покупки у партнёров
    акции. Наслаждайтесь покупками без&nbsp;лишних забот!
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout title={title} description={description} noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner orderNum="1" />
      <SmartWeeks
        title="Кэшбэк-мания"
        description="Каждую неделю с 17.03 добавляем новых партнёров акции из категорий, указанных ниже"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в&nbsp;розыгрыше"
        variant="nedeliUmnuxRassrochek"
        orderNum="3"
      />
      <FormPKW
        isGreenApprove
        progressBar
        formBtnText="Оформить карту"
        title={formTitle}
        withTimer
        dataLayerName="shortPersonalForm"
        orderNum="4"
      />
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        variant="nedeliUmnyhRassrochek"
        indent={false}
        withTitle
        orderNum="5"
      />
      <NewCalculator textSlider="Траты за месяц" orderNum="6" />
      <Partners
        title="Более 260 000 магазинов-партнеров"
        subTitle={parntersSubTitle}
        orderNum="7"
      />
      <TwoChoice
        orderNum="8"
        variant="nedeliUmnyhRassrochek"
        textBlockTitle={textBlockTitle}
        secondTextBlockTitle={secondTextBlockTitle}
      />
      <MoreBenefitsRedisign additionalEventInDataLayer={false} bottomBlock={false} orderNum="9" />
      <BottomCta variant="pkwRedesign" BgImg={RefinRedesign} orderNum="10" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="12" />
      <Reviews orderNum="12" />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/nedeli-umnyh-rassrochek/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
